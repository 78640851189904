<template>
  <master-layout>

    <ion-fab
      vertical="top"
      horizontal="start"
      slot="fixed"
      class="closebutton"
      @click="closeRating()"
    >
      <ion-fab-button
        color="light"
      >
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <div class="container">
      <div class="centered-content">
        <h2>Hogy érezted magad?</h2>
        <h3 v-if="winery">{{ winery.Name }}</h3>

        <h1 class="stars">
          <span
            v-for="i in 5"
            :key='i'
            @click="stars=i"
          >
            <ion-icon
              :name="starClicked(i)"
              color="primary"
            />
          </span>
        </h1>
      </div>
    </div>

    <div
      class="button-container"
      slot="fixed"
      vertical="bottom"
      horizontal="end"
    >
      <ion-button
        v-if="!stars"
        disabled
        shape="round"
        expand="block"
      >
        Küldés
      </ion-button>
      <ion-button
        v-else
        shape="round"
        expand="block"
        @click="sendRating()"
      >
        Küldés
      </ion-button>
    </div>

  </master-layout>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import {
  IonButton, IonFab, IonFabButton, IonIcon, loadingController
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  close, star, starOutline
} from 'ionicons/icons'

export default {
  name: 'RatingStars',
  components: {
    IonButton, IonFab, IonFabButton, IonIcon
  },
  data () {
    return {
      winery: null,
      stars: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    }),
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory',
      fromPage: 'getFromPage'
    })
  },
  created () {
    addIcons({
      'close': close,
      'star': star,
      'star-outline': starOutline
    })
  },
  ionViewWillEnter () {
    this.winery = this.wineryInMemory
    this.stars = null
  },
  ionViewWillLeave () {
    this.setWineryInMemory(null)
    this.setFromPage(null)
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory',
      setMyWineriesChanged: 'setMyWineriesChanged',
      setRatingInMemory: 'setRatingInMemory',
      setFromPage: 'setFromPage'
    }),

    async sendRating () {
      this.presentLoading()
      const ratings = await axios.get(process.env.VUE_APP_STRAPI_URI + 'ratings?User=' + this.authData.userId)
      const ratingsMade = ratings.data
      const alreadyRated = ratingsMade.findIndex(x => x.Winery.id === this.winery.id)

      if (alreadyRated == -1) {
        this.setRatingInMemory({
          User: this.authData.userId,
          Winery: this.winery.id,
          Stars: this.stars,
          Comment: null
        })
        this.winery = null
        this.Stars = null
        this.setMyWineriesChanged(true)
        await this.loading.dismiss()
        this.$router.push('/app/rating-comment')

      } else {
        await this.loading.dismiss()
        alert('Ezt a borászatot már értékelted!')
        this.setWineryInMemory(null)
        this.$router.push('/boraszatok')
      }
    },

    closeRating () {
      if (this.fromPage == 'boraszatok') {
        this.$router.go(-1)
      } else {
        this.$router.push('/boraszatok')
      }
    },

    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
      await this.loading.present()
    },

    starClicked (i) {
      if (this.stars >= i) {
        return 'star'
      } else {
        return 'star-outline'
      }
    }
  }
}
</script>

<style scoped>
h3{
  font-family: Alphabet;
  text-align: center;
  color: var(--ion-color-primary);
  margin-top: 35px;
}
.stars{
  font-size: 40px;
}
.closebutton{
  margin-top: calc(10px + env(safe-area-inset-top));
}
.container{
  height: 100%;
  display: flex;
  align-items: center;
}
.centered-content{
  width: 100%;
}
.button-container{
  width: 100%;
  bottom: calc(30px + env(safe-area-inset-bottom));
}
ion-button{
  margin: 0 20px;
}
</style>
